<template>
    <div>
        <van-nav-bar title="消息记录" left-text="返回" right-text="全部已读" @click-right="allRead()" @click-left="back" fixed left-arrow/>
        
        <div class="login_form">
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" :error.sync="error" error-text="请求失败，点击重新加载" @load="getMessage">
                <van-cell @click="readMsg(index)" v-for="(item,index) in list" :key="index" 
                 :label="item.time.replace('T', ' ')" >
                <template #title>
                    <span v-if="user.userNameNeedTranslate == 1 && item.msg != null && item.msg.indexOf('$userName=') != -1">
                        <span>{{item.msg1}}</span>
                        <TranslationOpenDataText type='userName' :openid='item.msg2'></TranslationOpenDataText>
                        <span>{{item.msg3}}</span>
                    </span>
                    <span v-else-if="item.msg != null">{{item.msg}}</span>
                    <span v-else>{{msgType[item.type]}}</span>
                </template>
                <div >
                    <span v-if="item.checked == 1" style="color:green">已读</span>
                    <span v-if="item.checked == 0" style="color:red">未读</span>
                </div>
                </van-cell>
            </van-list>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                msgType:["日报审核未通过","有新任务"],
                user: JSON.parse(localStorage.userInfo),
                
                total: 0,
                page: 1,
                size: 20,
                list: [],
                loading: false,
                finished: false,
                error: false,
                refreshing: false,
                
                show: false,
                title: "标题",
                
            };
        },
        created() {
        },
        methods: {

            // 全部已读
            allRead() {
                this.$axios.post("/information/checkAll", {})
                .then(res => {
                    if(res.code == "ok") {
                        // 重新获取消息
                        this.getMessage();
                    } 
                }).catch(err=> {this.$toast.clear();});
            },
            
            // 返回
            back() {
                history.back();
            },

            getmsgtext(text){
                return text.split('$')[1].split('=')[1]
            },

            readMsg(index) {
                var item = this.list[index];
                if (item.type == 0) {
                    //日报
                    var date = item.content; 
                    this.$axios.post("/information/check", {id:item.id
                    })
                    .then(res => {
                        if(res.code == "ok") {
                            //跳转到对应的日报上进行修改
                            this.getMessage();
                            this.$router.push('/edit?date='+date);
                        } 
                    }).catch(err=> {this.$toast.clear();});
                } else {
                    var date = item.content; 
                    this.$axios.post("/information/check", {id:item.id
                    })
                    .then(res => {
                        if(res.code == "ok") {
                            this.getMessage()
                            if (item.type == 1 || (item.msg != null && item.msg.indexOf('任务') > 0)) {
                                //跳到待办任务上
                                this.$router.push('/task'); 
                            } else if (item.type == 3) {
                                //跳到费用报销上
                                this.$router.push('/expense'); 
                            } 
                        } 
                    }).catch(err=> {this.$toast.clear();});
                }
                
            },
            
            //获取消息
            getMessage() {
                if (this.refreshing) {
                    this.list = [];
                    this.refreshing = false;
                }
                if(this.total == this.list.length && this.list.length != 0) {
                    this.loading = false;
                    this.finished = true;
                    return false;
                }
                this.$axios.post("/information/list", {
                })
                .then(res => {
                    if(res.code == "ok") {
                        this.loading = false;
                        this.finished = true;
                        this.list = res.data;
                        for(let i in this.list){
                            if(this.list[i].msg != null && this.list[i].msg.indexOf('$userName=') != -1){
                                this.list[i].msg1 = this.list[i].msg.split('$')[0]
                                this.list[i].msg2 = this.list[i].msg.split('$')[1].split('=')[1]
                                this.list[i].msg3 = this.list[i].msg.split('$')[2]
                            }
                        }
                    } else {
                        this.$toast.fail('获取失败');
                    }
                }).catch(err=> {this.$toast.clear();});
            },

            onRefresh() {
                this.finished = false;
                this.loading = true;
                this.page = 1;
                this.getMessage();
            },

        },

        mounted() {
        }
    };
</script>

<style lang="less" scoped>
    .van-cell__title {
      min-width: 90%;
    }
    .login_form {
        margin-top: 46px;
    }

    .one_report {
        margin-bottom: 15px;
    }

    .form_text {
        margin: 15px 0 30px;
        padding: 0 12px;
    }
    
    .form_btn {
        text-align: right;
    }

    .form_btn button {
        margin-left: 10px;
    }

    .one_report_data {
        margin-bottom: 20px;
        padding: 0 22px;
        div {
            line-height: 30px;
        }
    }
    .userCheckbox {
        padding: 10px;;
    }
</style>
<style lang="less">
    .van-nav-bar .van-icon , .van-nav-bar__text {
        color: #20a0ff;
    }
</style>
